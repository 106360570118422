import { Alias } from './types'

export const aliases: Alias = {
  login: '',
  DataHealth: 'data-health',
  TourPlanning: 'artist-venue-insights',
  TourMarketing: 'event-insights',
  'tour-planning': 'artist-venue-insights',
  'tour-marketing': 'event-insights',
}
