import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '@components/ChartCard'
import { Dropdown, DropdownOption } from '@components/Dropdown'
import { SingleEventContentProps } from '@components/EventContent'
import { SecondaryMarketBySectionTable } from '@components/TourPlanning/SecondaryMarketChartCard/SecondaryMarketBySectionTable'
import { SecondaryMarketStackedBarChart } from '@components/TourPlanning/SecondaryMarketChartCard/SecondaryMarketStackedBarChart'
import { SecondaryMarketTimeSeriesSalesChart } from '@components/TourPlanning/SecondaryMarketChartCard/SecondaryMarketTimeSeriesSalesChart'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ChartBarIcon, TableCellsIcon } from '@heroicons/react/24/outline'
import { MinusIcon, PlusIcon, PresentationChartBarIcon } from '@heroicons/react/24/solid'
import { useDebounce } from '@hooks'
import { cn } from '@utils/className'
import { subtractDays, toISOStringDatePart } from '@utils/date'
import { SecondaryMarketTimeSeriesInventoryChart } from './SecondaryMarketTimeSeriesInventoryChart'

enum DisplayType {
  StackedBarChart = 'stackedBarChart',
  Table = 'table',
}

type SecondaryMarketChartCardProps<AdditionalParamsOnInternalChange> = Pick<
  SingleEventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'pdfHeader'
  | 'stringfiedFilterContext'
  | 'secondaryMarket'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
> & { eventDate: string }
type InputNumberProps = {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  text: string
  className?: string
}

export function NumberInput(props: InputNumberProps) {
  const [inputValue, setInputValue] = useState<number>(props.value)
  const { min, max, value, onChange, text } = props
  const [delay, setDelay] = useState(1000)
  const valueQuery = useDebounce(inputValue.toString(), delay)
  const buttonClassName =
    'bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed'
  useEffect(() => {
    const day = parseInt(valueQuery)
    if (day >= min && day <= max) onChange(day)
    else setInputValue(value)
    setDelay(1000)
  }, [valueQuery, onChange, min, max, value])

  return (
    <div className={cn('flex items-center self-center gap-2 mb-2', props.className)}>
      <span className="text-sm text-gray-500 dark:text-gray-400">Min({min})</span>
      <div className="relative flex items-center max-w-[100rem] w-[25rem]">
        <button
          className={buttonClassName}
          disabled={value === min}
          onClick={() => {
            setDelay(0)
            setInputValue(inputValue - 1)
          }}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <input
          type="text"
          className="bg-gray-50 border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
          value={inputValue || 0}
          onChange={(e) => {
            const day = parseInt(e.target.value)
            setInputValue(day)
          }}
        />
        <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
          {text}
        </div>
        <button
          className={cn(buttonClassName, 'rounded-e-lg rounded-s-none')}
          disabled={value === max}
          onClick={() => {
            setDelay(0)
            setInputValue(inputValue + 1)
          }}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>
      <span className="text-sm text-gray-500 dark:text-gray-400">Max({max})</span>
    </div>
  )
}

export function SecondaryMarketChartCard<AdditionalParamsOnInternalChange>(
  props: SecondaryMarketChartCardProps<AdditionalParamsOnInternalChange>,
) {
  const { t } = useTranslation('tour_marketing')
  const { chartType } = props.secondaryMarket
  //   const [listingXAxis, setListingXAxis] = useState<[AggType, AxisType]>([AggType.Day, AxisType.DaysUntilShow])
  //   const [avgPriceXAxis, setAvgPriceXAxis] = useState<[AggType, AxisType]>([AggType.Day, AxisType.DaysUntilShow])
  const { daysFromEvent, setDaysFromEvent, maxDayFromEvent, minDayFromEvent } = props.secondaryMarket
  const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.StackedBarChart)

  const {
    loading: isLoadingSecondaryMarket,
    pdfHeader,
    stringfiedFilterContext,
    secondaryMarket,
    eventDate,
    additionalParamsForInternalChange,
  } = props

  const handleDayFromEventChange = useCallback(
    (days: number | undefined) => {
      setDaysFromEvent(days, additionalParamsForInternalChange)
    },
    [setDaysFromEvent],
  )
  const dropdownOptions: DropdownOption[] = [
    {
      id: DisplayType.StackedBarChart,
      label: t('common:stacked_bar_chart'),
      icon: ChartBarIcon,
      onClick: () => setDisplayType(DisplayType.StackedBarChart),
    },
    {
      id: DisplayType.Table,
      label: t('common:table'),
      icon: TableCellsIcon,
      onClick: () => setDisplayType(DisplayType.Table),
    },
  ]
  const chartsByType = {
    [DisplayType.StackedBarChart]: (
      <>
        <SecondaryMarketStackedBarChart
          secondarySeriesData={secondaryMarket.timeSeriesInventoryBySectionData}
          name="Ticket Quantity By Section"
          valuePropertyName="total_tickets_listed"
          labelKey="secondary_market_chart.total_ticket_listed"
        />
        <SecondaryMarketStackedBarChart
          secondarySeriesData={secondaryMarket.timeSeriesInventoryBySectionData}
          name="Avg Price By Section"
          valuePropertyName="avg_listing_price"
          labelKey="secondary_market_chart.average_listing_price"
          yAxisFormatter="${value}"
        />
      </>
    ),
    [DisplayType.Table]: (
      <>
        <SecondaryMarketBySectionTable
          id={VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket}
          seriesData={secondaryMarket.timeSeriesInventoryBySectionData}
        />
        <SecondaryMarketBySectionTable
          id={VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket}
          seriesData={secondaryMarket.timeSeriesInventoryBySectionData}
          showAvgPrice={true}
        />
      </>
    ),
  }

  const latestDate =
    props.secondaryMarket.timeSeriesInventoryData.days.length > 0
      ? props.secondaryMarket.timeSeriesInventoryData.days[
          props.secondaryMarket.timeSeriesInventoryData.days.length - 1
        ].file_date
      : undefined
  const dayFromEventDate =
    eventDate && daysFromEvent !== undefined
      ? toISOStringDatePart(subtractDays(new Date(eventDate), daysFromEvent))
      : '-'

  return (
    <ChartCard
      exportOptions={props.exportOptions}
      hideDownload
      page={props.page}
      id={VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket}
      visualization={{ id: VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket, subTypes: [chartType] }}
      title={t('secondary_market_chart.title')}
      info={t('secondary_market_chart.info')}
      lastUpdatedDate={latestDate}
      chart={
        <div className="flex flex-col">
          <div className="grid grid-cols-2">
            {secondaryMarket.timeSeriesInventoryData.days.length > 0 && (
              <>
                <div className="col-span-2">
                  <SecondaryMarketTimeSeriesInventoryChart
                    secondaryMarketTimeSeriesInventoryData={secondaryMarket.timeSeriesInventoryData.days}
                  />
                </div>
              </>
            )}
            {secondaryMarket.timeSeriesSalesData.length > 0 && (
              <div className="col-span-2">
                <SecondaryMarketTimeSeriesSalesChart
                  secondaryMarketTimeSeriesSalesData={secondaryMarket.timeSeriesSalesData}
                />
              </div>
            )}
            {secondaryMarket.timeSeriesInventoryBySectionData.length > 0 && <>{chartsByType[displayType]}</>}
          </div>
          <NumberInput
            min={minDayFromEvent || 0}
            max={maxDayFromEvent || 300}
            value={daysFromEvent || 0}
            onChange={handleDayFromEventChange}
            text={`Day from event(${dayFromEventDate})`}
            className="group-[.pdf-report]:hidden"
          />
          <div className="hidden group-[.pdf-report]:block self-center">
            <span className="text-gray-500 text-sm">Day from event:</span>{' '}
            <span className="font-semibold">
              {daysFromEvent} ({dayFromEventDate})
            </span>
          </div>
          <Dropdown options={dropdownOptions} defaultOption={dropdownOptions[0]} />
        </div>
      }
      showChart={
        [...secondaryMarket.timeSeriesInventoryBySectionData, ...secondaryMarket.timeSeriesInventoryData.days]?.length >
        0
      }
      noDataIcon={PresentationChartBarIcon}
      isLoading={isLoadingSecondaryMarket}
      exportFilename={`secondary_market_sold_average${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
      pdfHeader={pdfHeader}
    />
  )
}
