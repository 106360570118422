import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EChartsOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { EChartsComponent } from '@components/EChartsComponent'
import { SecondaryMarketTimeSeriesSalesData } from '@types'
import { formatUtils } from '@utils'
import { getChartStyle, showAnimation, showSlider } from '@utils/charts'
import { formatNumber } from '@utils/format'

interface SecondaryMarketTimeSeriesSalesChartProps {
  secondaryMarketTimeSeriesSalesData?: SecondaryMarketTimeSeriesSalesData[]
}

export const SecondaryMarketTimeSeriesSalesChart = ({
  secondaryMarketTimeSeriesSalesData = [],
}: SecondaryMarketTimeSeriesSalesChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const [chartWidth, setChartWidth] = useState<number | null>(null)
  const observer = useRef<ResizeObserver | null>(null)
  const { t } = useTranslation('tour_marketing')

  const handleRef = useCallback((node: HTMLDivElement) => {
    if (node && !observer.current) {
      observer.current = new ResizeObserver((entries) => {
        setChartWidth(entries[0].contentBoxSize?.[0].inlineSize)
      })
      observer.current.observe(node)
    }
  }, [])

  useEffect(() => {
    return () => {
      observer?.current?.disconnect()
      observer.current = null
    }
  }, [])
  const total = useMemo(() => {
    return secondaryMarketTimeSeriesSalesData.reduce(
      (acc, data) => {
        return {
          total_sold: acc.total_sold + data.quantity_sold,
          total_price: acc.total_price + data.total_order_value,
        }
      },
      { total_sold: 0, total_price: 0 },
    )
  }, [secondaryMarketTimeSeriesSalesData])
  const getChartOption = useCallback(async () => {
    const totalValueData = secondaryMarketTimeSeriesSalesData
      .map((data) => [data.event.days_from_event, data.total_order_value])
      .sort((a, b) => b[0] - a[0])

    const avgPriceData = secondaryMarketTimeSeriesSalesData
      .map((data) => [data.event.days_from_event, data.avg_price])
      .sort((a, b) => b[0] - a[0])

    const quantitySoldData = secondaryMarketTimeSeriesSalesData
      .map((data) => [data.event.days_from_event, data.quantity_sold || 0])
      .sort((a, b) => b[0] - a[0])

    const purchaseDate = secondaryMarketTimeSeriesSalesData
      .map((data) => [data.event.days_from_event, data.purchase_date] as [number, string])
      .sort((a, b) => b[0] - a[0])

    const maxWidth = chartWidth ? chartWidth / 2 - 40 : null
    const nameTruncate = maxWidth ? { maxWidth } : {}
    setChartOption({
      title: {
        left: 'center',
        top: 28,
        subtextStyle: {
          fontWeight: 'bolder',
          fontSize: 16,
        },

        subtext: `${total.total_sold} tickets have sold for a total value of $${formatUtils.formatNumber(
          total.total_price,
          {
            maximumFractionDigits: 2,
          },
        )}`,
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const date = purchaseDate.find((d) => d[0] === params.data[0])?.[1]
          const avgPrice = avgPriceData.find((d) => d[0] === params.data[0])?.[1]
          const totalValue = totalValueData.find((d) => d[0] === params.data[0])?.[1]
          const quantitySold = quantitySoldData.find((d) => d[0] === params.data[0])?.[1]
          return `${t('secondary_market_sales_chart.days_from_event')}: ${params.data[0]}<br />
            ${t('secondary_market_sales_chart.average_price')}: $${formatUtils.formatNumber(avgPrice || 0, {
            maximumFractionDigits: 2,
          })}<br />
          
            ${t('secondary_market_sales_chart.total_order_value')}: $${formatUtils.formatNumber(totalValue || 0, {
            maximumFractionDigits: 2,
          })}<br />
            ${t('secondary_market_sales_chart.quantity_sold')}: ${formatUtils.formatNumber(quantitySold || 0)}<br />
            ${t('secondary_market_sales_chart.inventory_item_date')}: ${formatUtils.formatDateNumbersOnly(date)}`
        },
      },
      legend: {
        top: '0',
        type: 'scroll',
      },
      grid: {
        left: '3%',
        right: '4%',
        containLabel: true,
      },
      xAxis: {
        name: t('secondary_market_sales_chart.days_from_event'),
        nameGap: 24,
        nameLocation: 'middle',
        min: function (value) {
          return value.min - 1
        },
        max: function (value) {
          return value.max + 1
        },
        axisLabel: {
          formatter: (value: string) => {
            return formatNumber(value, { maximumFractionDigits: 0 })
          },
        },
        inverse: true,
      },
      yAxis: [
        {
          name: t('secondary_market_sales_chart.quantity_sold'),
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },

          nameLocation: 'end',
          nameTruncate,
          axisLine: {
            onZero: false,
          },
        },
        {
          name: t('secondary_market_sales_chart.average_price'),
          type: 'value',
          nameTextStyle: {
            align: 'right',
          },
          nameLocation: 'end',
          nameTruncate,
          axisLabel: {
            formatter: '${value}',
          },
        },
      ],
      series: [
        {
          name: t('secondary_market_sales_chart.average_price'),
          type: 'line',
          stack: '',
          data: avgPriceData,
          yAxisIndex: 1,
        },
        {
          name: t('secondary_market_sales_chart.quantity_sold'),
          type: 'bar',
          stack: '',
          yAxisIndex: 0,
          data: quantitySoldData,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
        },
      ],
      animation: showAnimation(),
    })
  }, [secondaryMarketTimeSeriesSalesData, chartWidth, t])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} ref={handleRef} />
}
