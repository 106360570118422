import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { EventContentRef, SingleEventContentProps } from '@components/EventContent'
import { CompetingEvents } from '@components/EventContent/CompetingEvents'
import { Summary } from '@components/EventContent/Summary'
import { SubNavItem } from '@components/SubNav'
import { SingleEventCumulativeTicketsSoldChartCard } from '@components/TourPlanning/CumulativeTicketsSoldChartCard'
import { TimeSeriesChartCard } from '@components/TourPlanning/CumulativeTicketsSoldChartCard/TimeSeriesChartCard/TimeSeriesChartCard1'
import { SecondaryMarketChartCard } from '@components/TourPlanning/SecondaryMarketChartCard'
import { StarEventEntity } from '@types'

export const EventContent = forwardRef(
  <T,>(props: SingleEventContentProps<T & { event: StarEventEntity }>, ref: ForwardedRef<EventContentRef>) => {
    const { t } = useTranslation('tour_marketing')
    const summaryRef = useRef<HTMLDivElement>(null)
    const cumulativeTicketsSoldRef = useRef<HTMLDivElement>(null)
    const secondaryMarketRef = useRef<HTMLDivElement>(null)
    const competitorsRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        get subnavItems() {
          return [
            { element: summaryRef.current as HTMLElement, label: t('summary.title') },
            {
              element: cumulativeTicketsSoldRef.current as HTMLElement,
              label: t('primary_market.title'),
            },
            {
              element: secondaryMarketRef.current as HTMLElement,
              label: 'Secondary Market',
            },
            { element: competitorsRef.current as HTMLElement, label: t('competing_events.title') },
          ].filter((v) => !!v) as SubNavItem[]
        },
      }),
      [t],
    )

    const summaryProps = {
      loading: props.loading,
      summaryData: props.summaryData,
      secondaryMarket: {
        ...props.secondaryMarket,
        timeSeriesInventoryData: props.secondaryMarket.timeSeriesInventoryData.days,
      },
      stringfiedFilterContext: props.stringfiedFilterContext,
      exportOptions: props.exportOptions,
      page: props.page,
    }

    const cumulativeTicketsSoldProps = {
      stringfiedFilterContext: props.stringfiedFilterContext,
      exportOptions: props.exportOptions,
      page: props.page,
      pdfHeader: props.pdfHeader,
    }

    const competitorsProps = {
      loading: props.loading,
      stringfiedFilterContext: props.stringfiedFilterContext,
      eventFilter: props.eventFilter,
      hasEventFilter: props.hasEventFilter,
      competitors: props.competitors,
      exportOptions: props.exportOptions,
      page: props.page,
      additionalParamsForInternalChange: props.additionalParamsForInternalChange,
    }
    const secondaryMarketProps = {
      loading: props.loading,
      pdfHeader: props.pdfHeader,
      stringfiedFilterContext: props.stringfiedFilterContext,
      secondaryMarket: props.secondaryMarket,
      exportOptions: props.exportOptions?.map((option) => ({
        ...option,
        checked: option.id === 'pdf-report-hide-secondary-market-chart',
      })),
      page: props.page,
      additionalParamsForInternalChange: props.additionalParamsForInternalChange,
      eventDate: props.event?.performance_date,
    }
    const timeSeriesProps = {
      loading: false,
      stringfiedFilterContext: props.stringfiedFilterContext,
      pdfHeader: props.pdfHeader,
      hasEventFilter: props.hasEventFilter,
      selectedEvent: props.event,
      customEvents: props.customEvents,
      benchmark: props.benchmark,
      exportOptions: props.exportOptions,
      additionalParamsForInternalChange: props.additionalParamsForInternalChange,
    }
    return (
      <>
        <div ref={summaryRef}>
          <Summary {...summaryProps} />
        </div>
        <div>
          <div ref={cumulativeTicketsSoldRef}>
            <h2 className="text-xl md:text-3xl font-semibold dark:text-white mb-5 md:mb-6">Primary Market</h2>
            <div className="group-[.pdf-report-hide-timeseries-chart]:hidden mb-4">
              <TimeSeriesChartCard {...timeSeriesProps} />
            </div>
            <div className="group-[.pdf-report-hide-timeseries-chart]:hidden">
              <SingleEventCumulativeTicketsSoldChartCard {...cumulativeTicketsSoldProps} />
            </div>
          </div>
        </div>
        <div ref={secondaryMarketRef}>
          <h2 className="text-xl md:text-3xl font-semibold dark:text-white my-5 md:my-6">Secondary Market</h2>
          <div className="group-[.pdf-report-hide-secondary-market-chart]:hidden">
            <SecondaryMarketChartCard {...secondaryMarketProps} />
          </div>
        </div>

        <div ref={competitorsRef}>
          <CompetingEvents {...competitorsProps} />
        </div>
      </>
    )
  },
) as <T>(props: SingleEventContentProps<T> & { ref: ForwardedRef<EventContentRef> }) => JSX.Element
