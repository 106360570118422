import { cn } from '@utils/className'

export function Footer() {
  return (
    <>
      <div
        className={cn(
          'text-sm text-center text-gray-500 dark:text-gray-400 py-8 px-4',
          'mt-8 border-t border-t-gray-200 w-full dark:border-t-gray-800',
          'group-[.pdf-report]:hidden',
        )}
      >
        © 2023 Carbon Arc |{' '}
        <a
          href="https://www.carbonarc.co/privacy"
          target="_blank"
          className="text-blue-600 dark:text-blue-500 hover:underline"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </>
  )
}
