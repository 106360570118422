import { RoleName } from 'carbonarc-ui'
import { App } from '@config/site/app'
import { ExternalPage, Page } from '@config/site/app/pages'

export const AppPageMinimumRoleRequired: Partial<Record<App, Partial<Record<Page | ExternalPage, RoleName>>>> = {
  [App.STAR]: {
    [Page.OVERVIEW]: RoleName.CLIENT_USER,
    [Page.EVENT_INSIGHTS]: RoleName.CLIENT_USER,
    [Page.ARTIST_VENUE_INSIGHTS]: RoleName.CLIENT_USER,
    [Page.PREDICTION_ASSESSMENT]: RoleName.CLIENT_USER,
    [Page.DATA_HEALTH]: RoleName.CLIENT_USER,
    [ExternalPage.GITBOOK]: RoleName.CLIENT_USER,
  },
  [App.MUSIC]: {
    [Page.PREDICTION_ASSESSMENT]: RoleName.CLIENT_USER,
    [ExternalPage.GITBOOK]: RoleName.CLIENT_USER,
  },
}
