import {
  DEFAULT_SECONDARY_MARKET_CHART_TYPE,
  EventDataProps,
  EventDataSliceStateCreator,
} from '@stores/slices/eventDataSlice'
import { CompetitorFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import {
  competitorFilterFieldsToCompetitorFilters,
  competitorFiltersToQueryParams,
} from '@stores/slices/eventDataSlice/competingEvents/utils'
import * as api from '@stores/slices/eventDataSlice/eventDataSliceApi'
import { sortCustomEventFilterFields } from '@stores/slices/eventDataSlice/utils'
import { createPaginatedSlice } from '@stores/slices/paginatedSlice/paginatedSlice'
import { CompetitorEntity } from '@types'

export function createEventDataSlice<AdditionalParamsOnInternalChange>(
  {
    getApiFetch,
    getFilterContext,
    onSetCompetitorsFilters,
    onSetBenchmark,
    onSetCustomEvents,
    onSetSecondaryMarket,
  }: EventDataProps<AdditionalParamsOnInternalChange>,
  ...[set, get, store]: Parameters<EventDataSliceStateCreator<AdditionalParamsOnInternalChange>>
): ReturnType<EventDataSliceStateCreator<AdditionalParamsOnInternalChange>> {
  return {
    timeSeriesEntity: [],
    setTimeSeriesEntity: (timeSeriesEntity) => set({ timeSeriesEntity }),

    secondaryMarketTimeSeriesData: [],
    setSecondaryMarketTimeSeriesData: (secondaryMarketTimeSeriesData) => set({ secondaryMarketTimeSeriesData }),

    secondaryMarketTimeSeriesInventoryData: [],
    setSecondaryMarketTimeSeriesInventoryData: (secondaryMarketTimeSeriesInventoryData) =>
      set({ secondaryMarketTimeSeriesInventoryData }),

    secondaryMarketTimeSeriesInventoryDataV2: {
      days: [],
      weeks_from_event: [],
      weeks_since_sales_opened: [],
    },

    setSecondaryMarketTimeSeriesInventoryDataV2: (secondaryMarketTimeSeriesInventoryData) =>
      set({ secondaryMarketTimeSeriesInventoryDataV2: secondaryMarketTimeSeriesInventoryData }),

    secondaryMarketTimeSeriesSalesData: [],

    secondaryMarketTimeSeriesInventoryDataV3: {
      days: [],
      weeks_from_event: [],
    },

    secondaryMarketTimeSeriesListingsDataV3: {
      days: [],
      weeks_from_event: [],
    },

    setSecondaryMarketTimeSeriesInventoryDataV3: (secondaryMarketTimeSeriesInventoryData) =>
      set({ secondaryMarketTimeSeriesInventoryDataV3: secondaryMarketTimeSeriesInventoryData }),

    secondaryMarketMaxDayFromEvent: undefined,
    setSecondaryMarketMaxDayFromEvent: (secondaryMarketMaxDayFromEvent) => {
      set({ secondaryMarketMaxDayFromEvent })
    },

    secondaryMarketMinDayFromEvent: undefined,
    setSecondaryMarketMinDayFromEvent: (secondaryMarketMinDayFromEvent) => {
      set({ secondaryMarketMinDayFromEvent })
    },
    secondaryMarketTimeSeriesInventoryBySectionData: [],
    setSecondaryMarketTimeSeriesInventoryBySectionData: (secondaryMarketTimeSeriesInventoryData) =>
      set({ secondaryMarketTimeSeriesInventoryBySectionData: secondaryMarketTimeSeriesInventoryData }),

    secondaryMarketPriceAndTierData: [],
    setSecondaryMarketPriceAndTierData: (secondaryMarketPriceAndTierData) => set({ secondaryMarketPriceAndTierData }),

    secondaryMarketChartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE,
    setSecondaryMarketChartType: (secondaryMarketChartType, additionalParamsForInternalChange) => {
      set({ secondaryMarketChartType })
      onSetSecondaryMarket?.(additionalParamsForInternalChange)
    },

    eventSummaryData: null,
    setEventSummaryData: (eventSummaryData) => set({ eventSummaryData }),

    customEvents: [],
    isSelectingCustomEvents: false,
    setCustomEvents: (events, additionalParamsForInternalChange) => {
      const customEvents = events.map(sortCustomEventFilterFields)
      set({ isSelectingCustomEvents: true, customEvents })
      onSetCustomEvents?.(additionalParamsForInternalChange)
    },

    selectedBenchmark: null,
    setSelectedBenchmark: (benchmark, additionalParamsForInternalChange) => {
      if (benchmark) {
        set({ selectedBenchmark: benchmark })
      } else {
        set({
          selectedBenchmark: null,
          isSelectingCustomEvents: true,
          customEvents: [],
          multipleCustomTimeSeriesEntities: [],
        })
      }
      onSetBenchmark?.(additionalParamsForInternalChange)
    },

    multipleCustomTimeSeriesEntities: [],
    fetchMultipleCustomTimeSeriesData: () => {
      const apiFetch = getApiFetch()
      return api.fetchMultipleCustomTimeSeriesData(set, apiFetch, get().customEvents)
    },

    competitorsSummaryLoading: false,
    competitorsFilters: [],
    setCompetitorsFilters: (competitorsFilters, additionalParamsForInternalChange) => {
      set({ competitorsFilters })
      onSetCompetitorsFilters?.(additionalParamsForInternalChange)
      const apiFetch = getApiFetch()
      get().competitors.goToPage(1, apiFetch)
      const eventFilters = getFilterContext()
      const competitorsFiltersParsed = competitorFilterFieldsToCompetitorFilters(competitorsFilters)
      const filters = { ...eventFilters, ...competitorsFiltersParsed } as CompetitorFilters
      set({ competitorsSummaryLoading: true })
      api.fetchCompetitorsSumaryData(set, apiFetch, filters).finally(() => {
        set({ competitorsSummaryLoading: false })
      })
    },
    competitorsSummaryData: [],
    setCompetitorsSummaryData: (competitorsSummaryData) => set({ competitorsSummaryData }),
    getCompetitorsFilterContext: () => {
      const pageFilters = getFilterContext()
      const filters = competitorFilterFieldsToCompetitorFilters(get().competitorsFilters)
      return { ...pageFilters, ...filters } as CompetitorFilters
    },
    competitors: createPaginatedSlice<CompetitorEntity, CompetitorFilters>(
      {
        fieldName: 'competitors',
        fetchUrl: '/data/competitor_density',
        getFilters: () => get().getCompetitorsFilterContext(),
        toQueryParams: (f: CompetitorFilters) => competitorFiltersToQueryParams(f),
      },
      set as any,
      get as any,
      store as any,
    ),

    resetDataSlice: () => {
      set({
        timeSeriesEntity: [],
        secondaryMarketTimeSeriesData: [],
        secondaryMarketPriceAndTierData: [],
        eventSummaryData: null,
        customEvents: [],
        isSelectingCustomEvents: false,
        selectedBenchmark: null,
        multipleCustomTimeSeriesEntities: [],
        competitorsFilters: [],
        competitorsSummaryData: [],
      })
    },
  }
}
