import { isEqual } from 'lodash'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { clearLocalContext, getLocalContext, saveLocalContext } from '@stores/singleEventStore/localStorageContext'
import { benchmarkToUrl, contextToUrl, filtersToQueryParams, urlToContext } from '@stores/singleEventStore/queryParams'
import { fetchEventWithSeries } from '@stores/singleEventStore/singleEventApi'
import {
  Filters,
  SingleEventContext,
  SingleEventStoreGetFunction,
  SingleEventStoreProps,
  SingleEventStoreSetFunction,
  SingleEventStoreState,
} from '@stores/singleEventStore/singleEventStoreTypes'
import { DEFAULT_SECONDARY_MARKET_CHART_TYPE, createEventDataSlice } from '@stores/slices/eventDataSlice'
import {
  competitorFilterFieldsToCompetitorFilters,
  competitorFiltersToCompetitorFilterFields,
} from '@stores/slices/eventDataSlice/competingEvents/utils'
import * as api from '@stores/slices/eventDataSlice/eventDataSliceApi'
import { createPaginatedSlice } from '@stores/slices/paginatedSlice/paginatedSlice'
import { Filters as TourMarketingFilters } from '@stores/tourMarketingStore/tourMarketingStoreTypes'
import { EventEntityWithSeries, StarEventEntity } from '@types'
import { formatUtils, stringUtils } from '@utils'
import { toISOStringDatePart } from '@utils/date'
import { updateSearchParam } from '@utils/url'

const defaultRange = {
  min: undefined,
  max: undefined,
  loading: false,
}

const CLEAR_CONTEXT = {
  filters: [],
  singleEventFilter: null,
  secondaryMarket: {
    chartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE,
  },
}

export const createSingleEventStore = (initialProps: SingleEventStoreProps) => {
  return createStore<SingleEventStoreState>()(
    immer(
      (set, get, store) =>
        ({
          ...initialProps,

          ...createEventDataSlice(
            {
              getApiFetch: () => get().getApiFetch(),
              onSetCompetitorsFilters: (additionalParamsForInternalChange) => {
                const competitorsFilters = get().competitorsFilters
                const context = get().context
                const newContext = {
                  ...context,
                  competitorsFilters: competitorsFilters.length
                    ? competitorFilterFieldsToCompetitorFilters(competitorsFilters)
                    : undefined,
                }
                set({ context: newContext, syncingUrl: true })
                get().updateUrlFromContext(additionalParamsForInternalChange.searchParamsHandler)
              },
              onSetBenchmark: (additionalParamsForInternalChange) => {
                const benchmark = get().selectedBenchmark
                let newContext: SingleEventContext
                const context = get().context
                if (benchmark) {
                  const customEvents = get().customEvents.filter((event) => event.length > 0)
                  newContext = {
                    ...context,
                    benchmark: {
                      option: benchmark,
                      events: customEvents,
                    },
                  }
                } else {
                  newContext = {
                    ...context,
                    benchmark: undefined,
                  }
                }
                set({ context: newContext, syncingUrl: true })
                additionalParamsForInternalChange.searchParamsHandler
              },
              onSetCustomEvents: (additionalParamsForInternalChange) => {
                const benchmark = get().selectedBenchmark
                let newContext: SingleEventContext
                const context = get().context
                if (benchmark) {
                  const customEvents = get().customEvents.filter((event) => event.length > 0)
                  newContext = {
                    ...context,
                    benchmark: {
                      option: benchmark,
                      events: customEvents,
                    },
                  }
                } else {
                  newContext = {
                    ...context,
                    benchmark: undefined,
                  }
                }
                set({ context: newContext, syncingUrl: true })
                const newParams =
                  additionalParamsForInternalChange.searchParamsHandler.searchParams + `&${benchmarkToUrl(newContext)}`
                additionalParamsForInternalChange.searchParamsHandler.replaceSearchParams(newParams)
              },
              onSetSecondaryMarket: (additionalParamsForInternalChange) => {
                const chartType = get().secondaryMarketChartType
                const context = get().context
                const newContext = {
                  ...context,
                  secondaryMarket: { chartType },
                }
                set({ context: newContext, syncingUrl: true })
                get().updateUrlFromContext(additionalParamsForInternalChange.searchParamsHandler)
              },
              getFilterContext: () => {
                const filters = get().context.singleEventFilter
                return {
                  artist: { id: filters?.artist },
                  venue: { id: filters?.venue },
                  perfDate: { id: filters?.date },
                  showType: { id: '' },
                } as TourMarketingFilters
              },
            },
            set,
            get,
            store,
          ),
          daysFromEvent: undefined,
          dateRange: defaultRange,
          setDateRange: (dateRange) => set({ dateRange }),
          capacityRange: defaultRange,
          setCapacityRange: (capacityRange) => set({ capacityRange }),

          loadStatus: 'not_initiated',
          event: {} as EventEntityWithSeries,
          isInitiated: () => get().loadStatus !== 'not_initiated',
          isLoadingEvents: () => get().loadStatus === 'loading',

          selectEvent: (singleEventFilter) => {
            const context = {
              ...get().context,
              benchmark: undefined,
              singleEventFilter,
            }
            set({
              context,
              syncingUrl: true,
              daysFromEvent: undefined,
              customEvents: [],
              multipleCustomTimeSeriesEntities: [],
            })
            get().fetchContext()
          },
          isShowingSingleEvent: () => get().context.singleEventFilter !== null,

          syncingUrl: false,
          isLoadingEvent: true,
          context: CLEAR_CONTEXT,
          pageFilters: [],
          setPageFilters: (pageFilters: Filters) => {
            set({ pageFilters })
          },

          clearData: (searchParamsHandler) => {
            set({ context: CLEAR_CONTEXT, syncingUrl: true })
            clearLocalContext()
            get().events.reset()
            get().resetDataSlice()
            get().updateUrlFromContext(searchParamsHandler)
          },

          initLoad: async (searchParamsHandler: SearchParamsObject) => {
            if (get().loadStatus === 'not_initiated') {
              try {
                await get().loadFromUrl(searchParamsHandler)
              } catch (err: any) {
                if (err.message === 'InvalidParams') {
                  await get().loadFromLocalStorage(searchParamsHandler)
                }
              }
            } else {
              set({ syncingUrl: true })
              get().updateUrlFromContext(searchParamsHandler)
            }
          },

          loadFromUrl: async (searchParamsHandler) => {
            const params = searchParamsHandler.searchParams
            if (get().syncingUrl) return
            if (!isValidParams(params)) throw new Error('InvalidParams')
            const newContext = urlToContext(params)
            if (!isEqual(newContext, get().context)) {
              const competitorsFilters = newContext.competitorsFilters
                ? competitorFiltersToCompetitorFilterFields(newContext.competitorsFilters)
                : []
              const secondaryMarketChartType =
                newContext.secondaryMarket?.chartType || DEFAULT_SECONDARY_MARKET_CHART_TYPE
              set({
                context: newContext,
                pageFilters: newContext.filters,
                competitorsFilters,
                selectedBenchmark: newContext.benchmark?.option || null,
                customEvents: newContext.benchmark?.events || [],
                secondaryMarketChartType,
                daysFromEvent: params.get('daysFromEvent')
                  ? parseInt(params.get('daysFromEvent') as string)
                  : undefined,
              })

              await get().fetchContext()
            }
          },
          setDaysFromEvent: (daysFromEvent, additionalParamsForInternalChange) => {
            set({ daysFromEvent })
            const event = get().context.singleEventFilter
            if (event) {
              api.fetchSecondaryMarketTimeSeriesInventoryBySectionData(set, get().getApiFetch(), event, daysFromEvent)

              if (daysFromEvent !== undefined)
                additionalParamsForInternalChange.searchParamsHandler.replaceSearchParams(
                  updateSearchParam('daysFromEvent', daysFromEvent?.toString()),
                )
              else additionalParamsForInternalChange.searchParamsHandler.searchParams.delete('daysFromEvent')
            }
          },

          loadFromLocalStorage: async (searchParamsHandler) => {
            const newContext = getLocalContext()
            if (newContext) {
              set({ context: newContext, syncingUrl: true })
              get().fetchContext()
              get().updateUrlFromContext(searchParamsHandler)
            }
          },
          updateUrlFromContext: (searchParamsHandler) => {
            const context = get().context
            let urlParams = contextToUrl(context)
            if (get().daysFromEvent) {
              urlParams += `&daysFromEvent=${get().daysFromEvent}`
            }
            searchParamsHandler.replaceSearchParams(urlParams)
            if (!get().syncingUrl) {
              get()
                .loadFromUrl(searchParamsHandler)
                .catch(() => {
                  /* empty */
                })
            } else {
              set({ syncingUrl: false })
            }
          },
          events: createPaginatedSlice<StarEventEntity, Filters>(
            {
              fieldName: 'events',
              fetchUrl: '/data/eventsummarylist',
              getFilters: () => get().context.filters,
              toQueryParams: (f: Filters) => filtersToQueryParams(f),
            },
            set as any,
            get as any,
            store as any,
          ),
          fetchContext: async () => {
            set({ loadStatus: 'loading' })
            try {
              await load(set, get)
              saveLocalContext(get().context)
            } finally {
              set({ loadStatus: 'loaded' })
            }
          },
          stringifyEvent: () => {
            const { artist, venue } = getEventNameParams(get)

            const fsArtist = stringUtils.filefyString(artist || '')
            const fsVenue = stringUtils.filefyString(venue || '')

            return `${fsArtist}-${fsVenue}`
          },
          getPdfHeader: () => {
            const { artist, venue, date } = getEventNameParams(get)
            const perfDate = stringUtils.filefyString(date ? toISOStringDatePart(date) : '').replaceAll('_', '/')

            return { text: `${artist}\n${venue} \n${perfDate}`, lines: 3 }
          },

          getEventName: () => {
            const { artist, venue, date } = getEventNameParams(get)

            return artist && venue && date
              ? `${artist} | ${venue} | ${formatUtils.formatDateNumbersOnly(date)}`
              : 'Event'
          },
        } as SingleEventStoreState),
    ),
  )
}

const load = async (set: SingleEventStoreSetFunction, get: SingleEventStoreGetFunction) => {
  const context = get().context
  const { singleEventFilter, benchmark } = context
  const apiFetch = get().getApiFetch()

  const fetches = []
  if (singleEventFilter !== null) {
    const competitorsFilter = get().getCompetitorsFilterContext()
    fetches.push(
      ...[
        api.fetchEventSummaryData(set, apiFetch, singleEventFilter),
        api.fetchTimeSeriesData(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesData(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesSalesData(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesInventoryData(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesInventoryDataV2(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesInventoryDataV3(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesListingsDataV3(set, apiFetch, singleEventFilter),
        api.fetchSecondaryMarketTimeSeriesInventoryBySectionData(set, apiFetch, singleEventFilter, get().daysFromEvent),
        api.fetchSecondaryMarketPriceAndTierData(set, apiFetch, singleEventFilter),
        api.fetchCompetitorsSumaryData(set, apiFetch, competitorsFilter),
        get().competitors.fetch(competitorsFilter, apiFetch),
      ],
    )

    if (singleEventFilter.id) fetches.push(fetchEventWithSeries(set, apiFetch, Number(singleEventFilter.id)))

    if (benchmark) {
      api.fetchBenchmarkData(set, apiFetch, benchmark)
    }
  }

  await Promise.all(fetches)
  if (get().daysFromEvent === undefined) {
    set({ daysFromEvent: get().secondaryMarketMinDayFromEvent })
  }
}

const getValue = (
  opts: ({ id?: string | number; name?: string } | string | Date | undefined)[],
  matchId?: string | number | Date,
) => {
  for (const opt of opts) {
    if (typeof opt === 'string') {
      if (opt && (matchId == null || opt.toString() === matchId.toString())) return opt
    } else if (opt instanceof Date) {
      const optString = opt.toISOString()
      const matchString = matchId instanceof Date ? matchId.toISOString() : matchId?.toString()
      if (matchString == null || optString === matchString) return opt
    } else {
      if (opt?.id && opt?.name && (matchId == null || opt.id.toString() === matchId.toString())) return opt.name
    }
  }
  return null
}

const getEventNameParams = (get: SingleEventStoreGetFunction) => {
  const eventFilter = get().context.singleEventFilter
  const eventOnList = eventFilter
    ? get().events.items.find(
        (event) =>
          event.brand?.id?.toString() === eventFilter?.artist &&
          event.venue?.id?.toString() === eventFilter?.venue &&
          event.performance_date &&
          toISOStringDatePart(event.performance_date).toString() === eventFilter?.date,
      )
    : null

  const data = get().eventSummaryData
  const artist = getValue([eventOnList?.brand, data?.event?.brand], eventFilter?.artist) as string | null
  const venue = getValue([eventOnList?.venue, data?.event?.venue], eventFilter?.venue) as string | null
  const date = getValue(
    [eventOnList?.performance_date, data?.event?.performance_date],
    eventFilter?.date,
  ) as Date | null
  return { artist, venue, date }
}

export const clearParams = (searchParams: URLSearchParams | string | undefined | null) => {
  if (!searchParams) return null
  const cleanSearchParams = new URLSearchParams(searchParams)
  cleanSearchParams.delete('extended')
  cleanSearchParams.delete('download-pdf')
  cleanSearchParams.delete('pdf-report-hide')

  for (const [key, value] of cleanSearchParams.entries()) {
    if (!value) cleanSearchParams.delete(key)
  }

  return cleanSearchParams
}

const isValidParams = (params: URLSearchParams) => {
  const cleanParams = clearParams(params)
  if (cleanParams) cleanParams.delete('secondaryMarket[chartType]')
  return cleanParams && cleanParams.size > 0
}
