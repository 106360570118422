import { Button } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { feedbackEmail } from '@utils/emails'

export const FeedbackButton = () => {
  const { t } = useTranslation('common')

  return (
    <Button
      size="small"
      className="rounded-none px-3 py-2 text-sm font-medium fixed right-0 top-[50%] max-w-[40px]"
      href={feedbackEmail()}
    >
      <div className="flex flex-col-reverse gap-1">
        <ChatBubbleLeftRightIcon className="w-5 h-5 text-gray-white stroke-2 -rotate-90" />
        <span className="vertical-lr rotate-180">{t('feedback.button_label')}</span>
      </div>
    </Button>
  )
}
