import { CustomEventFilterGroups } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { CheckboxOption } from '@components/Export'
import { SubNavItem } from '@components/SubNav'
import { CompetitorFilterField, CompetitorFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import { EventFilter, SecondaryMarketChartType } from '@stores/slices/eventDataSlice/eventDataSliceTypes'
import { PaginatedSliceState } from '@stores/slices/paginatedSlice/paginatedSliceTypes'
import {
  CompetitorEntity,
  CompetitorSummaryData,
  EventsData,
  SecondaryMarketPriceAndTierData,
  SecondaryMarketTimeSeriesData,
  SecondaryMarketTimeSeriesInventoryBySectionData,
  SecondaryMarketTimeSeriesInventoryData,
  SecondaryMarketTimeSeriesInventoryDataV3,
  SecondaryMarketTimeSeriesListingsDataV3,
  SecondaryMarketTimeSeriesSalesData,
  StarEventSummaryEntity,
  TimeSeriesEntity,
} from '@types'
import { PdfHeader } from '@utils/pdf'

export enum BenchmarkOptionId {
  CustomEvents = 'customEvents',
}

export interface BenchmarkOption {
  id: BenchmarkOptionId
  label: string
}

type CustomEventsProps<AdditionalParamsOnInternalChange> = {
  timeSeriesEntities: TimeSeriesEntity[][]
  fetch: () => void
  events: CustomEventFilterGroups
  setEvents: (
    customEvents: CustomEventFilterGroups,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
}

type BenchmarkProps<AdditionalParamsOnInternalChange> = {
  selected: BenchmarkOption | null
  setSelected: (
    benchmark: BenchmarkOption | null,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
}

type SecondaryMarketProps<AdditionalParamsOnInternalChange> = {
  timeSeriesData: SecondaryMarketTimeSeriesData[]
  timeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryData[]
  priceAndTierData: SecondaryMarketPriceAndTierData[]
  chartType: SecondaryMarketChartType
  setChartType: (
    chartType: SecondaryMarketChartType,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
}

type PrimaryMarketProps = {
  timeSeriesEntity: TimeSeriesEntity[]
}

type CompetitorsProps<AdditionalParamsOnInternalChange> = {
  summaryData: CompetitorSummaryData[]
  summaryDataLoading: boolean
  data: PaginatedSliceState<CompetitorEntity, CompetitorFilters>
  filters: CompetitorFilterField[]
  setFilters: (
    filters: CompetitorFilterField[],
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
  getFilterContext: () => CompetitorFilters
}

export type EventContentProps<AdditionalParamsOnInternalChange> = {
  loading: boolean
  pdfHeader?: PdfHeader
  stringfiedFilterContext: string
  eventFilter: EventFilter | null
  hasEventFilter: boolean
  summaryData: StarEventSummaryEntity | null
  customEvents: CustomEventsProps<AdditionalParamsOnInternalChange>
  benchmark: BenchmarkProps<AdditionalParamsOnInternalChange>
  secondaryMarket: SecondaryMarketProps<AdditionalParamsOnInternalChange>
  primaryMarket: PrimaryMarketProps
  competitors: CompetitorsProps<AdditionalParamsOnInternalChange>
  additionalParamsForInternalChange: AdditionalParamsOnInternalChange
  exportOptions?: CheckboxOption[]
  page?: string
}

type SingleEventSecondaryMarketProps<AdditionalParamsOnInternalChange> = {
  timeSeriesData: SecondaryMarketTimeSeriesData[]
  timeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryDataV3
  timeSeriesListingsData: SecondaryMarketTimeSeriesListingsDataV3
  timeSeriesSalesData: SecondaryMarketTimeSeriesSalesData[]
  timeSeriesInventoryBySectionData: SecondaryMarketTimeSeriesInventoryBySectionData[]
  priceAndTierData: SecondaryMarketPriceAndTierData[]
  chartType: SecondaryMarketChartType
  daysFromEvent: number | undefined
  setDaysFromEvent: (
    daysFromEvent: number | undefined,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
  maxDayFromEvent: number | undefined
  minDayFromEvent: number | undefined
  setChartType: (
    chartType: SecondaryMarketChartType,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
}

export type SingleEventContentProps<AdditionalParamsOnInternalChange> = {
  loading: boolean
  pdfHeader?: PdfHeader
  stringfiedFilterContext: string
  eventFilter: EventFilter | null
  hasEventFilter: boolean
  summaryData: StarEventSummaryEntity | null
  customEvents: CustomEventsProps<AdditionalParamsOnInternalChange>
  benchmark: BenchmarkProps<AdditionalParamsOnInternalChange>
  secondaryMarket: SingleEventSecondaryMarketProps<AdditionalParamsOnInternalChange>
  primaryMarket: PrimaryMarketProps
  competitors: CompetitorsProps<AdditionalParamsOnInternalChange>
  additionalParamsForInternalChange: AdditionalParamsOnInternalChange
  exportOptions?: CheckboxOption[]
  page?: string
  event: EventsData
}

export type EventContentRef = {
  subnavItems: SubNavItem[]
}
